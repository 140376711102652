// determine the terminal punctuation point before the 'Read Me' link
const determineTerminalPunctuation = (character) => {
  // For terminal punctuation, just return character
  if (character === '?' || character === '.' || character === '!') {
    return character;
  }

  // Hide non terminal punctuation
  if (character === ',' || character === ';' || character === ':' || character === '-') {
    return '...';
  }

  // Add ellipses otherwise
  return `${character}...`;
};

// Take a string and returns a string truncated by characterCount to the nearest word
const truncateString = (str, characterCount) => {
  // if the string is smaller than the character count, just return
  if (str.length <= characterCount) {
    return str;
  }

  // splice to characterCount, remove trailing space if there is one
  let trunc = str.slice(0, characterCount - 1);
  trunc = trunc.slice(0, trunc.lastIndexOf(' '));

  // end of string
  const punctuation = determineTerminalPunctuation(trunc[trunc.length - 1]);

  // combine strings
  trunc = `${trunc.slice(0, trunc.length - 1)}${punctuation}`;

  return trunc;
};

export default truncateString;

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import Image from 'next/image';
import Typography from '../Typography';
import ChevronRight from '../Svg/ChevronRight';
import truncateString from '../../utils/truncate';

const CategoryGrid = ({
  categories,
  dataContentName,
  headline = 'Featured',
  pagination = [],
  parentSlug = '',
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  // translations
  const t = useTranslations('Categories');

  // Generates a category card's markup
  const getCategoryCard = (category, i) => {
    const url = parentSlug ? `${parentSlug}/${category.slug}/` : `${category.slug}/`;
    return (
      <Grid item xs={12} sm={12} md={4} lg={3} key={category.name} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
        <Link
          href={url}
          prefetch={false}
          className="category-card"
          itemProp="url"
          data-content-name={dataContentName || null}
          data-content-piece={dataContentName ? category.name : null}
          aria-label={`Category with slug: ${category.slug}`}
        >
          {category.name && (
            <div className="name">
              <Typography itemProp="name" htmlTagOverride="h2" variant="body-bold">
                {truncateString(category.name, 35)}
              </Typography>
            </div>
          )}
          {category?.thumbnail && (
            <div className="image-container">
              <Image
                alt={category.name}
                aria-hidden="true"
                className="image"
                fill
                itemProp="image"
                priority={i === 0}
                src={category.thumbnail}
                sizes="(max-width: 768px) 100vw,
                      (max-width: 1200px) 50vw,
                      33vw"
              />
            </div>
          )}
          <div className="see-all">
            <Typography variant="small">{t('card.cta')}</Typography>
            <ChevronRight width={16} height={16} fill="#78757E" />
          </div>
        </Link>
      </Grid>
    );
  };

  // need h1 on subcat pages, but h2 on home page
  const htmlTagOverride = parentSlug ? 'h1' : 'h2';

  return (
    <div className="category-grid">
      <div className="category-grid-header">
        {headline && htmlTagOverride && (
          <Typography htmlTagOverride={htmlTagOverride} variant="heading-700" addClasses="headline">
            {`${headline}`}
          </Typography>
        )}
      </div>

      <Grid
        container
        className="grid-container"
        alignItems="stretch"
        spacing={matches ? 3 : 2}
        itemScope
        itemType="https://schema.org/ItemList"
      >
        {categories?.map((category, i) => {
          return getCategoryCard(category, i);
        })}
      </Grid>
      {pagination}
    </div>
  );
};

CategoryGrid.propTypes = {
  categories: PropTypes.array.isRequired,
  dataContentName: PropTypes.string,
  headline: PropTypes.string,
  pagination: PropTypes.node,
  parentSlug: PropTypes.string,
};

export default CategoryGrid;

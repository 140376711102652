import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const ChevronRight = ({ height = 24, width = 24, fill = '#3F385A', className }) => {
  return (
    <div
      style={{
        width,
        height,
      }}
      className={classnames('svg', 'svg-chevron-right', className)}
    >
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ display: 'block' }}>
        <path d="M13.172 12.88l-4.95-4.95 1.414-1.414L16 12.88l-6.364 6.364-1.414-1.414 4.95-4.95z" fill={fill} />
      </svg>
    </div>
  );
};

ChevronRight.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default ChevronRight;
